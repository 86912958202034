<script>
import AutoNumeric from 'autonumeric';
import Gen from '../helper/Gen';

export default {
  props:{
    value: [String, Number],
    type:"",
  },
  data(){
    return {
      plugin: null,
      inputValue: "",
    }
  },
  computed:{
    myConfig(){
      if (this.type == "NPWP") {
        return "00.000.000.0-000.000"
      }else if(this.type == 'PHONE'){
        return "0000-0000-0000"
      }else{
        return "000000000000000"
      }
    }
  },
  async mounted(){
    await Gen.loadScript("/frontend/js/plugins/jquery.mask.js")
    setTimeout(()=>{
      $(this.$el)
        .val(this.value)
        .mask(this.myConfig)
        .on("keyup change", (e)=>{
          var value = e.target.value
          this.$emit("input", value.replace(/[^0-9]/g, ""))
        })
    }, 500)
  },
  methods:{
  },
  watch:{
    value(v){
      if(!v) return
      let value = v.replace(/[^0-9]/g, "")
      this.$emit("input", value)
      this.$set(this, 'inputValue', $(this.$el).mask(this.myConfig).masked(v));
    }
  }
}
</script>

<template>
  <input type="tel" pattern="[0-9]" v-model="inputValue" autocomplete="off">
</template>